<template>
  <div>
    <div
      v-if="loading"
      class="spinner-block"
    >
      <b-spinner />
    </div>
    <div v-else>
      <!-- Order info Cards -->
      <order-info-cards
        :order-info="shipmentDetails"
      />
      <!-- Searchbar and select products -->
      <template v-if="shipmentDetails.products.length">
        <div class="kp-sticky-search-wrapper">
          <products-search-section
            :shipment-list="shipmentList"
            @on-search="updateSearch"
            @on-select-all="selectAllProducts"
            @on-deselect-all="deselectAllProducts"
            @load-order="loadShipmentDetails"
          />
        </div>
        <!-- Shipment products -->
        <div>
          <b-tabs
            class="kp-tabs"
            nav-class="kp-nav-tabs"
            content-class="mt-3"
          >
            <b-tab
              title="Assigned to shipment"
              active
            >
              <products
                v-if="searchResult.length"
                :products="searchResult"
                :shipment-list="shipmentList"
                :enable-pagination="true"
                @total-value="updateOrderTotal"
                @load-shipment="loadShipmentDetails"
              />
            </b-tab>
          </b-tabs>
        </div>
      </template>
      <k-card
        v-else
        class="mt-4 d-flex justify-content-center align-items-center"
      >
        <span class="products-unavailable">No shipment products available</span>
      </k-card>
    </div>
  </div>
</template>

<script>
import { apiToastError } from '@/@core/utils/toast'
import { isProductInShipment } from '@/common-utils'
import {
  SET_ALL_PRODUCT_DESELECTED, SET_ALL_PRODUCT_SELECTED,
  SET_CURRENT_ORDER_PRODUCTS,
} from '@/store/modules/product.module'
import { FETCH_SHIPMENT, SET_EDITING_SHIPMENT, SET_SHIPMENT_SEQ_ID } from '@/store/modules/shipment.module'
import {
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { KCard } from '@kingpin-global/kingpin-ui'
import { productUtil } from '@kingpin-global/kingpin-utils-frontend'
import { BSpinner } from 'bootstrap-vue'
import OrderInfoCards from '../order-management/OrderInfoCards.vue'
import Products from '../product/Products.vue'
import ProductsSearchSection from '../product/ProductsSearchSection.vue'
import { setShipmentProductStatus } from './shipment-management-utils'

const { getProductAndOrderTotals } = productUtil

export default {
  name: 'ShipmentProductsTab',
  components: {
    OrderInfoCards,
    Products,
    BSpinner,
    ProductsSearchSection,
    KCard,
  },
  data() {
    return {
      shipmentId: this.$route.params.shipmentId,
      shipmentDetails: null,
      products: [],
      loading: false,
      searchingText: '',
      searchResult: [],
      shipmentList: [],
      totalQuantities: 0,
      totalPrice: 0,
    }
  },
  computed: {
    getSearchResultForProductsInShipment() {
      return this.searchResult
        ? this.searchResult.filter(product => isProductInShipment(product.status))
        : []
    },
    getSearchResultForProductsPreShipment() {
      return this.searchResult
        ? this.searchResult.filter(product => !isProductInShipment(product.status))
        : []
    },
    getOrderProductsInShipment() {
      return this.getSearchResultForProductsInShipment
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.shipmentId !== from.params.shipmentId) {
        this.shipmentId = to.params.shipmentId
        this.loadShipmentDetails()
      }
    },
  },
  created() {
    this.loadShipmentDetails()
  },
  methods: {
    loadShipmentDetails() {
      this.loading = true
      this.$store.dispatch(FETCH_SHIPMENT, this.shipmentId)
        .then(res => {
          this.shipmentDetails = res.data.data
          this.loading = false
          this.updateOrderTotal(this.shipmentDetails.totalPrice, this.shipmentDetails.totalQuantity)
          setShipmentProductStatus(this.shipmentDetails)
          this.initSearchResult()
          this.$store.commit(SET_EDITING_SHIPMENT, this.shipmentDetails)
          this.$store.commit(SET_CURRENT_ORDER_PRODUCTS, this.shipmentDetails.products)
          this.$store.commit(SET_SHIPMENT_SEQ_ID, this.shipmentDetails.shipmentSeqId)
          // reset the previously selected products
          this.deselectAllProducts()
          this.getShipmentList()
        })
        .catch(err => {
          apiToastError(err)
          this.loading = false
        })
    },
    getShipmentList() {
      this.$kpRequest({
        ...this.$kpEndpoint.shipment.getShipments,
        params: {
          orderId: this.shipmentDetails.orderId, sortBy: 'shipmentSeqId', asc: true, isMinimalData: true,
        },
      })
        .then(res => {
          this.shipmentList = res.data.data
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    updateSearch(searchingText) {
      this.searchingText = searchingText
      if (searchingText) {
        this.searchResult = this.shipmentDetails.products.filter(product => {
          const isStyleName = product.name.toLowerCase()
            .indexOf(searchingText.toLowerCase()) !== -1
          const isStyleCode = product.styleCode.toLowerCase()
            .indexOf(searchingText.toLowerCase()) !== -1
          return isStyleName || isStyleCode
        })
      } else {
        this.initSearchResult()
      }
    },
    initSearchResult() {
      this.searchResult = this.shipmentDetails.products
    },
    selectAllProducts() {
      this.$store.commit(SET_ALL_PRODUCT_SELECTED, this.shipmentDetails.products)
    },
    deselectAllProducts() {
      this.$store.commit(SET_ALL_PRODUCT_DESELECTED)
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    emitActions(action) {
      switch (action) {
        // when click cancel
        case 'cancel': {
          this.toggleSidebar()
          break
        }
        // When API returns success response
        case 'success': {
          this.loadShipmentDetails()
          this.toggleSidebar()
          break
        }
        default: {
          break
        }
      }
    },
    updateOrderTotal() {
      const role = this.$store.getters.role
      getProductAndOrderTotals(role, this.shipmentDetails)
    },
  },
}
</script>
